const removeMask = (value, mask) => {
  // Remueve todo menos letras @ y .
  if (mask.includes('.')) {
    return value.replace(/[^a-zA-Z0-9@]/g, '');
  }
  return value.replace(/[^a-zA-Z0-9@.]/g, '');
};
const applyMask = (value, mask) => {
  if (!mask) return value;
  value = removeMask(value, mask);
  let formattedValue = '';
  let valueIndex = 0;

  // Recorrer cada carácter de la máscara
  for (let i = 0; i < mask.length; i++) {
    const maskChar = mask[i];

    // Si el valor ya está vacío, detenemos el proceso
    if (valueIndex >= value.length) {
      break;
    }

    // Si el carácter de la máscara es un tipo de letra (alfabético)
    if (/[a-zA-Z]/.test(maskChar)) {
      // Si el valor actual es una letra, agregarla al valor formateado
      if (/[a-zA-Z]/.test(value[valueIndex])) {
        formattedValue += value[valueIndex];
        valueIndex++;
      } else {
        // Si no es una letra, salir (no permitir caracteres inválidos)
        break;
      }
    }
    // Si el carácter de la máscara es un número (0-9)
    else if (/\d/.test(maskChar)) {
      // Si el valor actual es un número, agregarlo al valor formateado
      if (/\d/.test(value[valueIndex])) {
        formattedValue += value[valueIndex];
        valueIndex++;
      } else {
        // Si no es un número, salir (no permitir caracteres inválidos)
        break;
      }
    }
    // Si es un carácter literal (por ejemplo, : o /)
    else {
      formattedValue += maskChar; // Se agrega tal cual
    }
  }

  return formattedValue;
};

export { applyMask, removeMask };
